// js
import React from "react";

// style
import './style/app.scss';

// html
import Header from "./components/Header";
import ProductSection from "./components/ProductSection";
import HowItWorksSection from "./components/HowItWorksSection";
import ReviewSection from "./components/ReviewSection";
import ContactSection from "./components/ContactSection";
import PriceSection from "./components/PriceSection";
import Footer from "./components/Footer";

function App() {
	return (
		<div className="App">
			<Header/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<ProductSection/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<HowItWorksSection/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<ReviewSection/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<PriceSection/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<ContactSection/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<Footer/>
		</div>
	);
}

export default App;
