import React, {useState} from 'react';
import records from '../images/records.png';
import stats from '../images/stats.png';
import messages from '../images/messages.png';
import product_picture from '../images/product-section-picture.png';
import reload from '../images/reload.png';
import StartButton from "./ buttons/StartButton";
import LearnMoreButton from "./ buttons/LearnMoreButton";
import MoreButton from "./ buttons/MoreButton";


const ProductSection = function () {
	return (
		<div className="product__section">
			<div className="product__section_1">
				<div className="product__section_1__title">
					<div className="product__section_1__title__name">
						<div className="buttons__section">Продукт</div>
					</div>
					<div className="product__section_1__title__description">
						Telegram-бот від Nemus - це рішення яке збільшить Вашу продуктивність
					</div>
				</div>
				<div className="product__cards">
					<div className="product__card">
						<div className="product__card__picture"><img src={records} alt=""/></div>
						<div className="product__card__title">Управління записами</div>
						<div className="product__card__description">Процес управління записами передбачає створення,
							скасування, відстеження записів тощо.
						</div>
						<div className="product__card__more"><LearnMoreButton/></div>
					</div>
					<div className="product__card">
						<div className="product__card__picture"><img src={stats} alt=""/></div>
						<div className="product__card__title">Статистика та Аналітика</div>
						<div className="product__card__description">Аналізуйте роботу Вашого бізнесу: визначайте ТОП
							майстрів, популярні послуги і кращих
							клієнтів.
						</div>
						<div className="product__card__more"><LearnMoreButton/></div>
					</div>
					<div className="product__card">
						<div className="product__card__picture"><img src={messages} alt=""/></div>
						<div className="product__card__title">Розсилання повідомлень</div>
						<div className="product__card__description">За допомогою нашого Telegram-бота Ви зможете
							розсилати повідомлення користувачам без
							додаткової плати за це.
						</div>
						<div className="product__card__more"><LearnMoreButton/></div>
					</div>
				</div>
			</div>
			<div className="product__section_2">
				<div className="product__section_2__content">
					<div className="product__section_2__content__text">
						<div className="product__section_2__content__text__title">Налаштований і легкий у використанні
							продукт
						</div>
						<div className="product__section_2__content__text__description">Вам не потрібно писати код чи
							вимоги до продукту, ми вже все зробили замість Вас. Широкий
							набір функціоналу розроблений спеціально під Ваші потреби.
						</div>
						<div className="product__section_2__content__text__benefits">
							<div className="product__section_2__content__text__benefits__picture">
								<img src={reload} alt="reload"/>
							</div>
							<div className="product__section_2__content__text__benefits__description">
								<div className="product__section_2__content__text__benefits__description__title">Бот
									працює цілодобово
								</div>
								<div className="product__section_2__content__text__benefits__description__adds">Бот
									виконуватиме свою роботу, як в день так і вночі.
								</div>
							</div>
						</div>
					</div>
					<div className="product__buttons">
						<StartButton/>
						<MoreButton/>
					</div>
				</div>
				<div className="product__section_2__picture">
					<img src={product_picture} alt="product_picture"/>
					<div className="product__section_2__back"></div>
				</div>
			</div>
		</div>
	);
};

export default ProductSection;