import React, { Component } from "react";
import Slider from "react-slick";
import commas from '../../images/commas.png';
import barber from '../../images/barber.png';


function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className}
			 style={{ ...style, display: "block" }}
			 onClick={onClick}
		>
			<svg width="40" height="71" viewBox="0 0 40 71" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="39.2106" y="35.1616" width="50.452" height="5" transform="rotate(135 39.2106 35.1616)" fill="#DADDD8"/>
				<rect x="4.09021" width="49.6937" height="5" transform="rotate(45 4.09021 0)" fill="#DADDD8"/>
			</svg>
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className}
			style={{ ...style, display: "block" }}
			onClick={onClick}
		>
			<svg width="40" height="71" viewBox="0 0 40 71" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="0.0183716" y="35.6748" width="50.452" height="5" transform="rotate(-45 0.0183716 35.6748)" fill="#DADDD8"/>
				<rect x="35.1387" y="70.8364" width="49.6937" height="5" transform="rotate(-135 35.1387 70.8364)" fill="#DADDD8"/>
			</svg>
		</div>
	);
}
export default class SimpleSlider extends Component {
	render() {
		const settings = {
			infinite: true,
			speed: 500,
			arrows: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
		};
		return (
			<div className="reviews__slider">
				<Slider {...settings}>
					<div className="reviews__slider__step">
						<div className="reviews__slider__step__text">
							<img src={commas} alt=""/>
							<span>The perfect tool for our ‘Service with Passion’ motto. From day one, SaaSup has inspired our trust and amazement. People often tell us that they love the chat and think it’s modern – something they have expected from a company.</span>
						</div>
						<div className="reviews__slider__step__author">
							<div className="reviews__slider__step__author__barber">
								<div className="reviews__slider__step__author__barber__image">
									<img src={barber} alt=""/>
								</div>
								<div className="reviews__slider__step__author__barber__name">
									James Toriff
								</div>
								<div className="reviews__slider__step__author__barber__job">
									Барбер
								</div>
							</div>
							<div className="reviews__slider__step__author__stars">
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
							</div>
						</div>
					</div>
					<div className="reviews__slider__step">
						<div className="reviews__slider__step__text">
							<img src={commas} alt=""/>
							<span>The perfect tool for our ‘Service with Passion’ motto. From day one, SaaSup has inspired our trust and amazement. People often tell us that they love the chat and think it’s modern – something they have expected from a company.</span>
						</div>
						<div className="reviews__slider__step__author">
							<div className="reviews__slider__step__author__barber">
								<div className="reviews__slider__step__author__barber__image">
									<img src={barber} alt=""/>
								</div>
								<div className="reviews__slider__step__author__barber__name">
									James Toriff
								</div>
								<div className="reviews__slider__step__author__barber__job">
									Барбер
								</div>
							</div>
							<div className="reviews__slider__step__author__stars">
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
							</div>
						</div>
					</div>
					<div className="reviews__slider__step">
						<div className="reviews__slider__step__text">
							<img src={commas} alt=""/>
							<span>The perfect tool for our ‘Service with Passion’ motto. From day one, SaaSup has inspired our trust and amazement. People often tell us that they love the chat and think it’s modern – something they have expected from a company.</span>
						</div>
						<div className="reviews__slider__step__author">
							<div className="reviews__slider__step__author__barber">
								<div className="reviews__slider__step__author__barber__image">
									<img src={barber} alt=""/>
								</div>
								<div className="reviews__slider__step__author__barber__name">
									James Toriff
								</div>
								<div className="reviews__slider__step__author__barber__job">
									Барбер
								</div>
							</div>
							<div className="reviews__slider__step__author__stars">
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
								<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.0979 1.8541C14.6966 0.0114832 17.3034 0.0114789 17.9021 1.8541L20.2658 9.12868C20.5335 9.95272 21.3014 10.5106 22.1679 10.5106H29.8168C31.7543 10.5106 32.5598 12.9899 30.9924 14.1287L24.8043 18.6246C24.1033 19.1339 23.81 20.0366 24.0777 20.8607L26.4414 28.1353C27.0401 29.9779 24.9311 31.5101 23.3637 30.3713L17.1756 25.8754C16.4746 25.3661 15.5254 25.3661 14.8244 25.8754L8.63631 30.3713C7.06888 31.5101 4.95992 29.9779 5.55862 28.1353L7.92228 20.8607C8.19002 20.0366 7.89671 19.1339 7.19573 18.6246L1.00761 14.1287C-0.559815 12.9899 0.245734 10.5106 2.18318 10.5106H9.83212C10.6986 10.5106 11.4665 9.95272 11.7342 9.12868L14.0979 1.8541Z" fill="#F1BC19"/>
								</svg>
							</div>
						</div>
					</div>
				</Slider>
			</div>
		);
	}
}