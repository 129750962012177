import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Product from "./pages/Product";
import Prices from "./pages/Prices";
import Contacts from "./pages/Contacts";
import UserAgreement from "./pages/UserAgreement";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Router forceRefresh={true}>
			<Routes forceRefresh={true}>
				<Route forceRefresh={true} path="/" element={ <App /> }></Route>
				<Route path="/product" element={ <Product /> }></Route>
				<Route path="/prices" element={ <Prices /> }></Route>
				<Route path="/contacts" element={ <Contacts /> }></Route>
				<Route path="/user-agreement" element={ <UserAgreement /> }></Route>
				<Route path="/privacy-policy" element={ <PrivacyPolicy /> }></Route>
			</Routes>
		</Router>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
