import React, {useState} from 'react';
import accept from "../images/accept.png";
import minus from "../images/minus.png";
import StartButton from "./ buttons/StartButton";


const PricesSection = function () {
	return (
		<div className="prices__section">
			<div className="product__section_1__title">
				<div className="product__section_1__title__name">
					<div className="buttons__section">Тарифи</div>
				</div>
				<div className="product__section_1__title__description">
					Прості та гнучкі тарифи
				</div>
			</div>
			<div className="prices__types">
				<div className="prices__types__title">
					Тип підписки
				</div>
				<div className="prices__types__body">
					<div className="prices__types__body__periods">
						<div className="prices__types__body__periods__year">За рік</div>
						<div className="prices__types__body__periods__month">За місяць</div>
					</div>
					<div className="prices__types__body__cards">
						<div className="prices__types__body__card">
							<div className="prices__types__body__card__title">
								<div className="price__card__title__text">Ізольований</div>
								<div className="price__card__title__add discount">-30%</div>
							</div>
							<div className="prices__types__body__card__desc first">
								<span className="prices__types__body__card__desc__fullPrice">$12.90 за місяць</span>
								<span className="prices__types__body__card__desc__discPrice">$9.90 за місяць</span>
							</div>
							<div className="prices__types__body__card__desc">
								<span className="prices__types__body__card__desc__fullPrice">від $154.80 за рік</span>
								<span className="prices__types__body__card__desc__discPrice">від $118.80 за рік</span>
							</div>
							<StartButton/>
						</div>
						<div className="prices__types__body__card business">
							<div className="prices__types__body__card__title">
								<div className="price__card__title__text">Бізнес</div>
								<div className="price__card__title__add popular">Популярний</div>
							</div>
							<div className="prices__types__body__card__desc first">
								<span className="prices__types__body__card__desc__fullPrice">$21.90 за місяць</span>
								<span className="prices__types__body__card__desc__discPrice">$17.90 за місяць</span>
							</div>
							<div className="prices__types__body__card__desc">
								<span className="prices__types__body__card__desc__fullPrice">від $262.80 за рік</span>
								<span className="prices__types__body__card__desc__discPrice">від $214.80 за рік</span>
							</div>
							<StartButton/>
						</div>
					</div>
				</div>
			</div>
			<div className="product__section_1__title">
				<div className="product__section_1__title__name">
					<div className="buttons__section">Ізольований</div>
				</div>
			</div>
			<div className="prices__isolated">
				<div className="prices__isolated__description">
					<div className="prices__isolated__description__title">Telegram-бот для одного майстра</div>
					<div className="prices__isolated__description__body">
						Тарифний план "Ізольований" містить два рівні доступу:
						<br/>
						<br/>
						<span>Адміністратор</span>
						<br/>
						<span>Користувач</span>
						<br/>
						<br/>
						Користувачі можуть створювати та скасовувати свої записи, переглядати локацію закладу,
						переглядати посилання на соціальну мережу та отримувати нагадування про запис за дві години та
						за добу до запису.
						<br/>
						<br/>
						Адміністратори ж мають доступ до розширеного функціоналу, такого як додавання та видалення часу
						роботи, послуг, категорій, змінення локації та додавання посилання-кнопки в меню. Додатково,
						адміністратори можуть переглядати календар записів, скасовувати записи, додавати нові записи та
						розсилати повідомлення та фотографії своїм користувачам.
					</div>
					<StartButton/>
				</div>
				<div className="prices__isolated__table">
					<div className="prices__isolated__table__header">
							<div className="prices__isolated__table__func__title">Функціонал</div>
						<div className="prices__isolated__table__header__users">
							<div className="prices__isolated__table__func__title">Адмін</div>
							<div className="prices__isolated__table__func__title">Користувач</div>
						</div>
					</div>
					<div className="prices__isolated__table__body">
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Додати/Скасувати запис</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Перегляд локації закладу</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Переглянути посилання на соціальну мережу</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Нагадування про запис за дві години до запису</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Додати/Видалити час роботи</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Додати/Видалити послуги</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Додати/Видалити категорію</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Перегляд календаря</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Змінити локацію</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Додати посилання-кнопку в меню</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Сповіщення про запис та скасування</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__isolated__table__item">
							<div className="prices__isolated__table__func__item">Розсилання повідомлень та фото користувачам</div>
							<div className="prices__isolated__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__isolated__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
					</div>
				</div>
				<div className="prices__isolated__table__back"></div>
			</div>
			<div className="product__section_1__title">
				<div className="product__section_1__title__name">
					<div className="buttons__section">Бізнес</div>
				</div>
			</div>
			<div className="prices__business">
				<div className="prices__business__description">
					<div className="prices__isolated__description__title">Telegram-бот для одного майстра</div>
					<div className="prices__business__description__body">
						Тарифний план "Бізнес" містить три рівні доступу:<br/><br/>
						<span>Користувач</span><br/>
						<span>Майстер</span><br/>
						<span>Адміністратор</span><br/>
						<br/>
						Користувачі в цьому тарифному плані мають такі ж самі можливості, як і в тарифному плані “Ізольований” та додатково можуть вибрати майстра, до якого записатись на послугу.
						<br/><br/>
						Майстри мають схожий функціонал, що і адміністротори, але їхні можливості заточені на своїх користувачів. Тобто майстри можуть розсилати повідомлення, фотографії своїм користувачам, тобто тим людям, які записувались, або записані на послуги безпосередньо цього майстра. Також у майстрів є можливість керувати своїм часом, записами, послугами, та календарем.
						<br/><br/>
						Адмінімтратор може керувати кожним з Майстрів, та переглядати їхню інформацію: записи, послуги та календар та видаляти чи додавати нових Майстрів. Адмін має додаткові можливості для розсилання повідомлень та фотографій для усіх користувачів.
					</div>
					<StartButton/>
				</div>
				<div className="prices__isolated__table">
					<div className="prices__business__table__header">
						<div className="prices__isolated__table__func__title">Функціонал</div>
						<div className="prices__isolated__table__header__users table_business">
							<div className="prices__isolated__table__func__title table_business_1">Користувач</div>
							<div className="prices__isolated__table__func__title table_business_2">Майстер</div>
							<div className="prices__isolated__table__func__title table_business_3">Адмін</div>
						</div>
					</div>
					<div className="prices__isolated__table__body">
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Додати/Скасувати запис</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Перегляд локації закладу</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Переглянути посилання на соціальну мережу</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Нагадування про запис за дві години до запису</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Додати/Видалити час роботи</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Додати/Видалити послуги</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Додати/Видалити категорію</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Перегляд календаря</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Змінити локацію</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Додати посилання-кнопку в меню</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Сповіщення про запис та скасування</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Розсилання своїм користувачам</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Додати/Видалити майстра</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Додати/Видалити час роботи для майстра</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Розсилання усім користувачам</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Розсилання клієнтам окремого майстра</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
						<div className="prices__business__table__item">
							<div className="prices__isolated__table__func__item">Генерація CSV (Excel) таблиці для календаря</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__accept">
								<img src={accept} alt="accept"/>
							</div>
							<div className="prices__business__table__func__item__minus">
								<img src={minus} alt="minus"/>
							</div>
						</div>
					</div>
				</div>
				<div className="prices__business__back"></div>
			</div>
		</div>
	);
};

export default PricesSection;