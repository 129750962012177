import Header from "../components/Header";
import Footer from "../components/Footer";

const PrivacyPolicy = function () {
	return (
		<div className="App">
			<Header/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<div className="user_agreement__section">
				<div className="product__section_1__title">
					<div className="product__section_1__title__name">
						<div className="buttons__section policy">Політика конфіденційності</div>
					</div>
					<div className="product__section_1__title__description policy">
						Політика конфіденційності
					</div>
					<div className="user_agreement__body">
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">1. Вступ</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									1.1. Конфіденційність відвідувачів нашого веб-сайту дуже важлива для нас, і ми прагнемо
									її захищати. Ця політика пояснює, що ми робимо з вашою особистою інформацією.
								</div>
								<div className="user_agreement__body__item__main__row">
									1.2. Згода на використання нами файлів cookie відповідно до умов цієї політики під час
									першого відвідування нашого веб-сайту дозволяє нам використовувати файли cookie щоразу,
									коли ви відвідуєте наш веб-сайт.
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">2. Як ми збираємо ваші особисті дані</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									2.1.Можна збирати, зберігати та використовувати наступні типи персональної інформації:
								</div>
								<div className="user_agreement__body__item__main__row">
									2.1.1. Інформація про ваш комп’ютер, включаючи IP-адресу, географічне розташування, тип
									і версію браузера та операційну систему
								</div>
								<div className="user_agreement__body__item__main__row">
									2.1.2. Інформація про ваші відвідування та використання цього веб-сайту, включаючи
									джерело перенаправлення, тривалість відвідування, перегляди сторінок і шляхи навігації
									веб-сайтом.
								</div>
								<div className="user_agreement__body__item__main__row">
									2.1.3. Інформація, яку ви вводите під час реєстрації на нашому веб-сайті, наприклад
									веб-сайт електронної пошти.
								</div>
								<div className="user_agreement__body__item__main__row">
									2.1.4. Інформація, яку ви вводите, щоб налаштувати підписку на наші електронні листи
									та/або інформаційні бюлетені.
								</div>
								<div className="user_agreement__body__item__main__row">
									2.1.5. Інформація, яка генерується під час користування нашим веб-сайтом, зокрема, коли,
									як часто та за яких обставин ви ним користуєтеся.
								</div>
								<div className="user_agreement__body__item__main__row">
									2.1.6. Інформація, що стосується всього, що ви купуєте, послуг, якими ви користуєтеся,
									або транзакцій, які ви робите через наш веб-сайт, включаючи ваше ім’я, адресу, номер
									телефону, адресу електронної пошти та дані кредитної картки.
								</div>
								<div className="user_agreement__body__item__main__row">
									2.1.7. Будь-яка інша особиста інформація, яку ви надсилаєте нам.
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">3. Використання особистої інформації</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									3.1. Особиста інформація, надана нам через наш веб-сайт, буде використана для цілей,
									зазначених у цій політиці або на відповідних сторінках веб-сайту. Ми можемо
									використовувати вашу особисту інформацію для наступного:
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.1. Адміністрування нашого сайту та бізнесу
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.2. Персоналізація нашого сайту для вас
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.3. Уможливлення використання послуг, доступних на нашому веб-сайті
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.4. Надсилання вам товарів, придбаних через наш сайт
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.5. Надання послуг, придбаних через наш сайт
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.6. Надсилання вам виписок, рахунків-фактур і нагадувань про оплату, а також отримання платежів від вас.
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.7. Надсилання вам немаркетингових комерційних повідомлень
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.8. Надсилання сповіщень електронною поштою, які ви спеціально запитали.
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.9. Надсилання вам нашої електронної розсилки, якщо ви підписалися на неї (ви можете будь-коли скасувати підписку).
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.10. Надсилання вам маркетингових повідомлень, що стосуються нашого бізнесу або бізнесу третіх осіб, які, на нашу думку, можуть вас зацікавити.
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.11. Надання третім особам статистичної інформації про наших користувачів.
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.12. Робота із запитами та скаргами, зробленими вами або про вас стосовно нашого веб-сайту
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.13. Підтримання безпеки нашого веб-сайту та запобігання шахрайству.
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.14. Перевірка дотримання положень та умов, що регулюють використання нашого веб-сайту.
								</div>
								<div className="user_agreement__body__item__main__row">
									3.1.15. Інше використання.
								</div>
								<div className="user_agreement__body__item__main__row">
									3.2. Якщо ви подаєте особисту інформацію для публікації на нашому веб-сайті, ми опублікуємо та іншим чином використаємо цю інформацію відповідно до ліцензії, яку ви нам надаєте.
								</div>
								<div className="user_agreement__body__item__main__row">
									3.3. Ваші налаштування конфіденційності можуть бути використані для обмеження публікації вашої інформації на нашому веб-сайті та можуть бути скориговані за допомогою елементів керування конфіденційністю на веб-сайті.
								</div>
								<div className="user_agreement__body__item__main__row">
									3.4. Без вашої явно висловленої згоди ми не будемо надавати вашу особисту інформацію будь-якій третій стороні для прямого маркетингу ними або будь-якою іншою третьою стороною.
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">4. Розкриття особистої інформації</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									4.1. Ми можемо розкривати вашу особисту інформацію будь-якому з наших співробітників,
									посадових осіб, страховиків, професійних консультантів, агентів, постачальників або
									субпідрядників у міру необхідності для цілей, викладених у цій політиці.
									<div className="user_agreement__body__item__main__row">4.2. Ми можемо розкривати вашу
										особисту інформацію будь-якому члену нашої групи компаній
										(це означає наші дочірні компанії, нашу кінцеву холдингову компанію та всі її
										дочірні
										компанії) у міру необхідності для цілей, викладених у цій політиці.</div>
									<div className="user_agreement__body__item__main__row">
										4.3. Ми можемо розкрити вашу особисту інформацію:
									</div>
									<div className="user_agreement__body__item__main__row">
										4.3.1. У тій мірі, в якій ми зобов’язані робити це згідно із законом;
									</div>
									<div className="user_agreement__body__item__main__row">
										4.3.2. У зв’язку з будь-яким поточним або майбутнім судовим розглядом.
									</div>
									<div className="user_agreement__body__item__main__row">
										4.3.3. Щоб встановити, реалізувати або захистити наші законні права (включаючи
										надання
										інформації іншим з метою запобігання та зменшення шахрайства
										кредитний ризик);
									</div>
									<div className="user_agreement__body__item__main__row">
										4.3.4. Покупцеві (або потенційному покупцеві) будь-якого бізнесу чи активу, який ми
										(або
										плануємо) продати.
									</div>
									<div className="user_agreement__body__item__main__row">
										4.3.5. Будь-якій особі, яка, на нашу обґрунтовану думку, може звернутися до суду чи
										іншого компетентного органу з проханням про розкриття такої особистої інформації,
										якщо,
										на нашу обґрунтовану думку, такий суд чи орган з розумною ймовірністю видадуть
										розпорядження про розкриття такої особистої інформації.
									</div>
									<div className="user_agreement__body__item__main__row">
										4.4. За винятком випадків, передбачених цією політикою, ми не будемо надавати вашу
										особисту інформацію
										треті особи.
									</div>
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">5. Міжнародні передачі даних</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									1.1. Конфіденційність відвідувачів нашого веб-сайту дуже важлива для нас, і ми прагнемо
									її захищати. Ця політика пояснює, що ми робимо з вашою особистою інформацією.
								</div>
								<div className="user_agreement__body__item__main__row">
									1.2. Згода на використання нами файлів cookie відповідно до умов цієї політики під час
									першого відвідування нашого веб-сайту дозволяє нам використовувати файли cookie щоразу,
									коли ви відвідуєте наш веб-сайт.
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">6. Зберігання особистої інформації</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									6.1. Розділ 6 визначає нашу політику та процедуру збереження даних, які розроблені, щоб забезпечити дотримання наших юридичних зобов’язань щодо збереження та видалення особистої інформації.
								</div>
								<div className="user_agreement__body__item__main__row">
									6.2. Особиста інформація, яку ми обробляємо для будь-якої мети або цілей, не повинна зберігатися довше, ніж це необхідно для цієї мети або цілей.
								</div>
								<div className="user_agreement__body__item__main__row">
									6.3. Незважаючи на інші положення цього розділу 6, ми зберігатимемо документи (включаючи електронні документи), що містять персональні дані:
								</div>
								<div className="user_agreement__body__item__main__row">
									6.3.1. У тій мірі, в якій ми зобов’язані робити це згідно із законом.
								</div>
								<div className="user_agreement__body__item__main__row">
									6.3.2. Якщо ми вважаємо, що документи можуть мати відношення до будь-якого поточного або майбутнього судового провадження.
								</div>
								<div className="user_agreement__body__item__main__row">
									6.3.3. Щоб встановити, реалізувати або захистити наші законні права (включаючи надання інформації іншим з метою запобігання шахрайству та зниження кредитного ризику).
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">7. Безпека вашої особистої інформації</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									7.1. Ми вживатимемо розумних технічних та організаційних заходів, щоб запобігти втраті, неправильному використанню або зміні вашої особистої інформації.
								</div>
								<div className="user_agreement__body__item__main__row">
									7.2. Ми зберігатимемо всю надану вами особисту інформацію на наших безпечних (захищених паролем і брандмауером) серверах.
								</div>
								<div className="user_agreement__body__item__main__row">
									7.3. Усі електронні фінансові операції, які здійснюються через наш веб-сайт, будуть захищені технологією шифрування.
								</div>
								<div className="user_agreement__body__item__main__row">
									7.4. Ви визнаєте, що передача інформації через Інтернет за своєю суттю є незахищеною, і ми не можемо гарантувати безпеку даних, які надсилаються через Інтернет.
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">8. Поправки</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									8.1. Ми можемо час від часу оновлювати цю політику, публікуючи нову версію на нашому веб-сайті. Ви повинні періодично перевіряти цю сторінку, щоб переконатися, що ви розумієте будь-які зміни в цій політиці. Ми можемо повідомляти вас про зміни в цій політиці електронною поштою або через систему приватних повідомлень на нашому веб-сайті.
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">9. Ваші права</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									9.1. Ви можете доручити нам надати вам будь-яку особисту інформацію про вас, яку ми маємо; надання такої інформації буде залежати від наступного:
								</div>
								<div className="user_agreement__body__item__main__row">
									9.1.1. надання відповідних доказів вашої особи (для цієї мети ми зазвичай приймаємо фотокопію вашого паспорта, засвідчену нотаріусом, а також оригінальну копію рахунку за комунальні послуги, де вказано вашу поточну адресу).
								</div>
								<div className="user_agreement__body__item__main__row">
									9.2. Ми можемо не надавати особисту інформацію, яку ви запитуєте, у межах, дозволених законом.
								</div>
								<div className="user_agreement__body__item__main__row">
									9.3. Ви можете будь-коли наказати нам не обробляти вашу особисту інформацію в маркетингових цілях.
								</div>
								<div className="user_agreement__body__item__main__row">
									9.4. На практиці зазвичай ви заздалегідь прямо погоджуєтеся на використання нами вашої особистої інформації в маркетингових цілях, або ми надаємо вам можливість відмовитися від використання вашої особистої інформації в маркетингових цілях.
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">10. Веб-сайти третіх осіб</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									10.1. Наш веб-сайт містить гіперпосилання на веб-сайти третіх осіб і детальну інформацію про них. Ми не контролюємо та не несемо відповідальності за політику та практику конфіденційності третіх сторін.
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">11. Актуалізація інформації</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									11.1. Будь ласка, повідомте нам, якщо особисту інформацію, яку ми зберігаємо про вас, потрібно виправити або оновити.
								</div>
							</div>
						</div>
						<div className="user_agreement__body__item policy__main">
							<div className="user_agreement__body__item__title">12. Файли cookies</div>
							<div className="user_agreement__body__item__main policy__main">
								<div className="user_agreement__body__item__main__row">
									12.1. Наш сайт використовує файли cookie. Файл cookie — це файл, що містить ідентифікатор (рядок літер і цифр), який надсилається веб-сервером у веб-браузер і зберігається в браузері. Потім ідентифікатор надсилається назад на сервер щоразу, коли браузер запитує сторінку з сервера. Файли cookie можуть бути «постійними» або «сеансовими» файлами: постійний файл cookie зберігатиметься веб-браузером і залишатиметься дійсним до закінчення встановленого терміну дії, якщо користувач не видалить його до закінчення терміну дії; сеансовий файл cookie, з іншого боку, закінчується в кінці сеансу користувача, коли веб-браузер закрито. Файли cookie зазвичай не містять жодної інформації, яка ідентифікує користувача, але особиста інформація, яку ми зберігаємо про вас, може бути пов’язана з інформацією, яка зберігається в файлах cookie та отримана з них. На нашому веб-сайті ми використовуємо як сеансові, так і постійні файли cookie.
									«Властивості браузера», «Конфіденційність», а потім «Додатково»;
								</div>
								<div className="user_agreement__body__item__main__row">
									12.2. Назви файлів cookie, які ми використовуємо на нашому веб-сайті, і цілі, для яких вони використовуються, наведені нижче:
								</div>
								<div className="user_agreement__body__item__main__row">
									12.2.1. ми використовуємо Google Analytics і Adwords на нашому веб-сайті, щоб розпізнавати комп’ютер, коли користувач відвідує веб-сайт/відстежує користувачів під час навігації веб-сайтом/увімкне використання кошика для покупок на веб-сайті / покращити зручність використання веб-сайту / аналізувати використання веб-сайту / адмініструвати веб-сайт / запобігати шахрайству та покращувати безпеку веб-сайту / персоналізувати веб-сайт для кожного користувача / націлювати рекламні оголошення, які можуть бути особливо цікавими для конкретних користувачів / описувати мету;
								</div>
								<div className="user_agreement__body__item__main__row">
									12.3. Більшість браузерів дозволяють відмовитися від прийому файлів cookie, наприклад:
								</div>
								<div className="user_agreement__body__item__main__row">
									12.3.1. в Internet Explorer (версія 10) ви можете заблокувати файли cookie за допомогою налаштувань заміни обробки файлів cookie, які доступні, натиснувши «Інструменти»,
								</div>
								<div className="user_agreement__body__item__main__row">
									12.3.2. у Firefox (версія 24) ви можете заблокувати всі файли cookie, натиснувши «Інструменти», «Параметри», «Конфіденційність», вибравши «Використовувати спеціальні налаштування для історії» зі спадного меню та знявши прапорець «Приймати файли cookie із сайтів».
								</div>
								<div className="user_agreement__body__item__main__row">
									12.3.3. у Chrome (версія 29) ви можете заблокувати всі файли cookie, увійшовши в меню «Налаштування та керування», натиснувши «Налаштування», «Показати розширені налаштування» та «Налаштування вмісту», а потім вибравши «Блокувати сайти від встановлення будь-яких даних» під заголовком «Cookies».
								</div>
								<div className="user_agreement__body__item__main__row">
									12.4. Блокування всіх файлів cookie негативно вплине на зручність використання багатьох веб-сайтів. Якщо ви заблокуєте файли cookie, ви не зможете використовувати всі функції нашого веб-сайту.
								</div>
								<div className="user_agreement__body__item__main__row">
									12.5. Ви можете видалити файли cookie, які вже зберігаються на вашому комп’ютері, наприклад:
								</div>
								<div className="user_agreement__body__item__main__row">
									12.5.1. в Internet Explorer (версія 10) ви повинні вручну видалити файли cookie (ви можете знайти інструкції щодо цього на http://support.microsoft.com/kb/278835).
								</div>
								<div className="user_agreement__body__item__main__row">
									12.5.2. у Firefox (версія 24) ви можете видалити файли cookie, натиснувши «Інструменти», «Параметри» та «Конфіденційність», потім вибравши «Використовувати спеціальні налаштування для історії», натиснувши «Показати файли cookie», а потім натиснувши «Видалити всі файли cookie» .
								</div>
								<div className="user_agreement__body__item__main__row">
									12.5.6. у Chrome (версія 29) ви можете видалити всі файли cookie, увійшовши в меню «Налаштування та керування», натиснувши «Налаштування», «Показати розширені параметри» та «Очистити дані веб-перегляду», а потім вибравши «Видалити файли cookie та інші сайти та дані плагіна», перш ніж натиснути «Очистити дані веб-перегляду».
								</div>
								<div className="user_agreement__body__item__main__row">
									12.6. Видалення файлів cookie негативно вплине на зручність використання багатьох веб-сайтів.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<Footer/>
		</div>
	);
};

export default PrivacyPolicy;