// js
import React from "react";

// style
import '../style/app.scss';

// html
import Header from "../components/Header";
import Footer from "../components/Footer";
import UserAgreementSection from "../components/UserAgreementSection";

function UserAgreement() {
	return (
		<div className="App">
			<Header/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<UserAgreementSection/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<Footer/>
		</div>
	);
}

export default UserAgreement;
