import LearnMoreButton from "./ buttons/LearnMoreButton";
import StartButton from "./ buttons/StartButton";

const PriceSection = function () {
	return (
		<div className="price__section">
			<div className="price__block">
				<div className="product__section_1__title">
					<div className="product__section_1__title__name">
						<div className="buttons__section">Тарифи</div>
					</div>
					<div className="product__section_1__title__description">
						Прості та гнучкі тарифи
					</div>
				</div>
				<div className="price__description">
					Весь перелік функціоналу, для кожного з тарифів ви можете дізнатись тут <span className="product__card__more"><LearnMoreButton/></span>
				</div>
				<div className="price__cards">
					<div className="price__card">
						<div className="price__card__title">
							<div className="price__card__title__text">Ізольований</div>
							<div className="price__card__title__add discount">-30%</div>
						</div>
						<div className="price__card__price">
							від $9.90 за місяць
						</div>
						<div className="price__card__description">
							<span>1 майстер (працівник)</span>
							<span>Сповіщення та нагадування про записи</span>
							<span>Розсилання повідомлень</span>
							<span>Управління календарем, послугами, категоріями та записами</span>
							<span>Аналітика записів</span>
						</div>
						<StartButton/>
					</div>
					<div className="price__card popular">
						<div className="price__card__title">
							<div className="price__card__title__text">Бізнес</div>
							<div className="price__card__title__add popular">Популярний</div>
						</div>
						<div className="price__card__price">
							від $17.90 за місяць
						</div>
						<div className="price__card__description">
							<span>До 20 майстрів</span>
							<span>Окремий рівень доступу для майстрів</span>
							<span>Сповіщення та нагадування про записи</span>
							<span>Розсилання повідомлень окремим групам клієнтів</span>
							<span>Аналітика записів та генерація CSV (Excel) звіту</span>
						</div>
						<StartButton/>
					</div>
					<div className="price__card">
						<div className="price__card__title">
							<div className="price__card__title__text">Кастомний</div>
						</div>
						<div className="price__card__price">
							від $9.90 за місяць
						</div>
						<div className="price__card__description">
							<span>Будь-які функції та можливості, які відповідають Вашим потребам </span>
							<span>Унікальний продукт</span>
							<span>Збільшить продажі та розширить аудиторію вашого бізнесу</span>
						</div>
						<StartButton/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PriceSection;