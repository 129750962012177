import React, {useState} from 'react';


const UserAgreementSection = function () {
	return (
		<div className="user_agreement__section">
			<div className="product__section_1__title">
				<div className="product__section_1__title__name">
					<div className="buttons__section">Угода</div>
				</div>
				<div className="product__section_1__title__description">
					Угода Користувача
				</div>
				<div className="user_agreement__body">
					<div className="user_agreement__body__title">
						Дана Угода користувача(далі також – «Угода») регулює відносини щодо використання функцій Веб
						сайту та загальні умови можливого надання Послуг між Виконавцем (далі також – «Власник Веб
						сайту»), з одного боку, та фізичною або юридичною особою Замовником, який прийняв умови цієї
						Угоди шляхом приєднання до цієї Угоди в цілому і беззастережно (далі також – «Користувач
						сайту»), з іншого боку, далі при спільному згадуванні іменовані «сторони», а окремо – «Сторона».
						<br/>Ця Угода та відносини Сторін, що виникли з цієї Угоди, регулюються законодавством.
					</div>
					<div className="user_agreement__body__item">
						<div className="user_agreement__body__item__title">1. Загальні положення Угоди</div>
						<div className="user_agreement__body__item__main">
							<div className="user_agreement__body__item__main__row">
								1.1 У цьому документі і що випливають або пов’язаним з ним відносинах Сторін
								застосовуються такі терміни та визначення:
							</div>
							<div className="user_agreement__body__item__main__row__first">
								<span>Веб сайт Власника/Веб сайт – інтернет-сайт, якій розміщений в домені Власника сайту і
								його субдоменах.</span>
								<span>Угода – дана Угода з усіма доповненнями і змінами.</span>
								<span>Адміністрація/представник Веб сайту, Власника Веб сайту – особа уповноважена Власником
								сайту на дії, передбачені Угодою. </span>
								<span>Відвідувач – будь-яка особа, яка перейшла на Веб сайт
								і знаходиться на ньому, використовуючи його властивості для ознайомлення з контентом,
								розміщеним на Веб сайті.</span>
								<span>Користувач – дієздатна фізична особа, яка приєдналася до цієї
								Угоди у власному інтересі або виступає від імені та в інтересах представленої ним
								юридичної особи.</span>
							</div>
							<div className="user_agreement__body__item__main__row">
								1.2. Будь-який відвідувач Веб сайту з власної волі може заповнити надану для цього форму
								і стати Користувачем.
							</div>
							<div className="user_agreement__body__item__main__row">
								1.3. Веб сайт містить інформацію і матеріали про товари і послуги, які в подальшому
								можуть бути надані Користувачеві в разі укладення реального договору. Якщо якась фізична
								особа зареєструвалася як Користувач від імені юридичної особи, яка надала їй на це
								право, це означає, що юридична особа приймає Угоду в повному обсязі з наступними з цього
								наслідками.
							</div>
							<div className="user_agreement__body__item__main__row">
								1.4. Зокрема, при використанні Веб сайту, Користувач не має права видавати себе за іншу
								людину або представника організації і/або співтовариства без достатніх на те прав, у
								тому числі за співробітників Веб сайту, а також застосовувати будь-які інші форми і
								способи незаконного представництва інших осіб в мережі Інтернет, а також вводити
								користувачів Веб сайту і його представників в оману, щодо властивостей і характеристик
								будь-яких суб’єктів або об’єктів.
							</div>
							<div className="user_agreement__body__item__main__row">
								1.5. У разі виявлення порушення прав та/або інтересів у зв’язку з використанням Веб
								сайту, в тому числі іншим Користувачем, слід повідомити про це Власника сайту, шляхом
								подання відповідного письмового повідомлення, повідомлення по телефону з докладним
								викладом обставин порушення і/або гіпертекстової посиланням на сторінку, що містить
								матеріали, якими порушуються відповідні права та/або інтереси Веб сайту.
							</div>
						</div>
					</div>
					<div className="user_agreement__body__item">
						<div className="user_agreement__body__item__title">2. Інтелектуальні права</div>
						<div className="user_agreement__body__item__main">
							<div className="user_agreement__body__item__main__row">
								2.1. Усі об’єкти, доступні за допомогою Веб сайту, у тому числі елементи дизайну, текст,
								графічні зображення, ілюстрації, відео, комп’ютерні програми, бази даних, музика, звуки
								та інші об’єкти, розміщені в рамках Веб сайту, є об’єктами виняткових прав Веб сайту та
								інших правовласників. Веб сайт надає Користувачеві право його функціонального
								використання в межах його загальних функціональних можливостей.
							</div>
							<div className="user_agreement__body__item__main__row">
								2.2. Використання Веб сайту іншими способами, в тому числі шляхом копіювання
								(відтворення) розміщеного на Веб сайті Контенту, а також елементів дизайну, комп’ютерних
								програм і баз даних, що входять до складу Веб сайту, їх декомпіляція, модифікація, і
								подальше поширення, публічний показ, доведення до загального відома, строго заборонені,
								якщо інше не передбачено цією Угодою та укладеними Договорами.
							</div>
							<div className="user_agreement__body__item__main__row">
								2.3. Користувач не має права відтворювати, повторювати і копіювати, продавати, а також
								використовувати для будь-яких комерційних цілей будь-якої частини Веб сайту (включаючи
								Контент, доступний Користувачу за допомогою Додатків), або доступ до них, крім тих
								випадків, коли користувач отримав такий дозвіл від Веб сайту/Власників Веб сайту, або,
								коли це прямо передбачено додатковими документами (Договорами, як Додатки і т.п.).
							</div>
							<div className="user_agreement__body__item__main__row">
								2.4. Використання Користувачем Веб сайту, а також розміщеного на ньому Контенту для
								особистого некомерційного використання допускається за умови дотримання всіх законів
								охорони авторського права, суміжних прав, товарних знаків, інших повідомлень про
								авторство, збереження імені (або псевдоніма) автора / найменування правовласника в
								незмінному вигляді, збереженні відповідного об’єкта в незмінному вигляді. Виняток
								становлять випадки, прямо передбачені законодавством, що регулює загальну захист даних
								або додатковими документами на використання Веб сайту.
							</div>
							<div className="user_agreement__body__item__main__row">
								2.5. Веб сайт може містити посилання на сайти в мережі Інтернет (сайти третіх осіб).
								Зазначені треті особи та їх Контент не перевіряються Веб сайтом на відповідність тим або
								іншим вимогам (достовірності, повноти і т.п.). Веб сайт не несе відповідальності за
								будь-яку інформацію, матеріали, розміщені на сайтах третіх осіб, до яких Користувач
								отримує доступ в зв’язку з використанням Веб сайту, а також за доступність таких сайтів
								або інформації та наслідки їх використання Користувачем.
							</div>
							<div className="user_agreement__body__item__main__row">
								2.6. Посилання (у будь-якій формі) на будь-який сайт, продукт, послугу, будь-яку
								інформацію комерційного або некомерційного характеру, розміщене на Веб сайті, не є
								схваленням або рекомендацією даних продуктів (послуг, діяльності) з боку Веб сайту, за
								винятком випадків, коли Веб сайт на це прямо вказує.
							</div>
						</div>
					</div>
					<div className="user_agreement__body__item">
						<div className="user_agreement__body__item__title">3. Зв’язок та повідомлення</div>
						<div className="user_agreement__body__item__main">
							<div className="user_agreement__body__item__main__row">
								3.1. Власник Веб сайту/Адміністрація/Представник сайту і особи, представниками яких є
								Веб сайт, мають право посилати Користувачеві на вказану їм електронну адресу, телефон,
								посилання на сторінку в соціальних мережах інформаційні електронні повідомлення про
								події, що відбуваються в рамках Веб сайту або у зв’язку з ним і осіб яких він
								представляє.
							</div>
						</div>
					</div>
					<div className="user_agreement__body__item">
						<div className="user_agreement__body__item__title">4. Заключні положення</div>
						<div className="user_agreement__body__item__main">
							<div className="user_agreement__body__item__main__row">
								4.1. Дана Угода регулюється і тлумачиться відповідно до чинного законодавства, що
								регулює загальну захист даних. Питання, не врегульовані цією Угодою, підлягають
								вирішенню відповідно до законодавства. Всі можливі суперечки, що випливають з відносин,
								які регулюються цією Угодою, вирішуються у порядку, встановленому чинним законодавством.
							</div>
							<div className="user_agreement__body__item__main__row">
								4.2. Якщо з тих чи інших причин одне або декілька положень цієї Угоди будуть визнані
								недійсними або що не мають юридичної сили, це не означає що решта положень є недійсними
								або не можуть застосовуватися.
							</div>
							<div className="user_agreement__body__item__main__row">
								4.3. Тимчасова бездіяльність з боку Веб сайту в разі порушення Користувачем або іншими
								Користувачами положень Угод не позбавляє Веб сайт права застосовувати відповідні дії на
								захист своїх інтересів пізніше, а також не означає, що Веб сайт відмовився від своїх
								прав у разі вчинення в подальшому подібних або схожих порушень.
							</div>
							<div className="user_agreement__body__item__main__row">
								4.4. Усі суперечки щодо Угоди або у зв’язку з ним підлягають розгляду у суді за місцем
								знаходження Веб сайту відповідно до чинного законодавства.
							</div>
							<div className="user_agreement__body__item__main__row">
								4.5. Дана Угода користувача розроблена і діє у рамках чинного Законодавства про захист
								фізичних осіб стосовно обробки персональних даних та про вільне переміщення таких даних.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserAgreementSection;