// js
import React from "react";

// style
import '../style/app.scss';

// html
import Header from "../components/Header";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import PricesSection from "../components/PricesSection";

function Prices() {
	return (
		<div className="App">
			<Header/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<PricesSection/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<ContactSection/>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<Footer/>
		</div>
	);
}

export default Prices;
