import ReviewSlider from './sliders/ReviewSlider'

const ReviewSection = function () {
	return (
		<div className="reviews__section">
			<div className="reviews__circle_1">

			</div>
			<div className="reviews__circle_2">

			</div>
			<div className="product__section_1__title">
				<div className="product__section_1__title__name">
					<div className="buttons__section rev">Відгуки</div>
				</div>
				<div className="product__section_1__title__description rev">
					Нам довіряють
				</div>
			</div>
			{/* eslint-disable-next-line react/jsx-pascal-case */}
			<ReviewSlider/>
			<div className="reviews__circle_3">

			</div>
			<div className="reviews__circle_4">

			</div>
		</div>
	);
};

export default ReviewSection;