import React, {useEffect, useState} from 'react';
import slider_pick_1 from '../images/slider_pick_1.png';
import slider_pick_2 from '../images/slider_pick_2.png';
import slider_pick_3 from '../images/slider_pick_3.png';
import slider_result_1 from '../images/slider_result_1.png';
import slider_result_2 from '../images/slider_result_2.png';
import slider_result_3 from '../images/slider_result_3.png';
import slider_phone_1 from "../images/phone_how_it_work_phone.png";
import StartButton from "./ buttons/StartButton";

const HowItWorksSection = () => {
    const [activeIndex, setActiveIndex] = useState(1);

    const handleAutomaticSlide = () => {
        const nextIndex = activeIndex === 3 ? 1 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    useEffect(() => {
        const intervalId = setInterval(handleAutomaticSlide, 5000);

        return () => clearInterval(intervalId);
    }, [activeIndex]);

    const handleNavItemClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <div className="how_it_works__section">
            <div className="product__section_1__title">
                <div className="product__section_1__title__name">
                    <div className="buttons__section how_it_works_button_mobile">Як це працює?</div>
                </div>
                <div className="product__section_1__title__description">
                    Ми розробили рішення для Вас
                </div>
            </div>
            <div className="how_it_works__sliderBlock">
                <div className="how_it_works__slider__nav">
                    <div
                        id="how_it_works__slider__nav__step_1"
                        className={`how_it_works__slider__nav__step ${activeIndex === 1 ? 'active' : ''}`}
                        onClick={() => handleNavItemClick(1)}
                    >
                        <div id="how_it_works__slider__nav__step__mobile_1"
                             className={`how_it_works__slider__nav__step__mobile ${activeIndex === 1 ? 'active' : ''}`}>
                            01. Залишайте заявку
                        </div>
                        <span>
             				 01. Залишайте заявку
            			</span>
                    </div>
                    <div
                        id="how_it_works__slider__nav__step_2"
                        className={`how_it_works__slider__nav__step ${activeIndex === 2 ? 'active' : ''}`}
                        onClick={() => handleNavItemClick(2)}
                    >
                        <div id="how_it_works__slider__nav__step__mobile_2"
                             className={`how_it_works__slider__nav__step__mobile ${activeIndex === 2 ? 'active' : ''}`}>
                            02. Очікуєте заявку
                        </div>
                        <span>
                            02. Очікуєте запуск
                        </span>
                    </div>
                    <div
                        id="how_it_works__slider__nav__step_3"
                        className={`how_it_works__slider__nav__step ${activeIndex === 3 ? 'active' : ''}`}
                        onClick={() => handleNavItemClick(3)}
                    >
                        <div id="how_it_works__slider__nav__step__mobile_3"
                             className={`how_it_works__slider__nav__step__mobile ${activeIndex === 3 ? 'active' : ''}`}>
                            03. Користуєтесь ботом
                        </div>
                        <span>
                          03. Користуєтесь ботом
                        </span>
                    </div>
                </div>
                <div className="how_it_works__slider__result">
                    <div id="how_it_works__slider__result__step_1"
                         className={`how_it_works__slider__result__step ${activeIndex === 1 ? 'active' : ''}`}>
                        <div className="how_it_works__slider__result__step__text">
                            <div className="how_it_works__slider__result__step__text__title">
                                <div className="how_it_works__slider__result__step__text__title__image">
                                    <img src={slider_pick_1} alt=""/>
                                </div>
                                <div className="how_it_works__slider__result__step__text__title__text">Залишайте заявку
                                    на створення Telegram бота
                                </div>
                            </div>
                            <div className="how_it_works__slider__result__step__info">
                                <div className="how_it_works__slider__result__step__info__left">
                                    <img src={slider_phone_1} alt=""/>
                                </div>
                                <div className="how_it_works__slider__result__step__info__right">
                                    <div className="how_it_works__slider__result__step__text__description">Заповнюєте
                                        форму в Telegram боті для створення заявки на підключення свого бізнесу до нашої
                                        системи
                                    </div>
                                    <div className="how_it_works__slider__result__step__text__button">
                                        <StartButton/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="how_it_works__slider__result__step__image">
                            <img src={slider_result_1} alt=""/>
                        </div>
                    </div>
                    <div id="how_it_works__slider__result__step_2"
                         className={`how_it_works__slider__result__step ${activeIndex === 2 ? 'active' : ''}`}>
                        <div className="how_it_works__slider__result__step__text">
                            <div className="how_it_works__slider__result__step__text__title">
                                <div className="how_it_works__slider__result__step__text__title__image">
                                    <img src={slider_pick_2} alt=""/>
                                </div>
                                <div className="how_it_works__slider__result__step__text__title__text">Залишайте заявку
                                    на створення Telegram бота
                                </div>
                            </div>
                            <div className="how_it_works__slider__result__step__info">
                                <div className="how_it_works__slider__result__step__info__left">
                                    <img src={slider_result_2} alt=""/>
                                </div>
                                <div className="how_it_works__slider__result__step__info__right">
                                    <div className="how_it_works__slider__result__step__text__description">Заповнюєте
                                        форму в Telegram боті для створення заявки на підключення свого бізнесу до нашої
                                        системи
                                    </div>
                                    <div className="how_it_works__slider__result__step__text__button">
                                        <StartButton/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="how_it_works__slider__result__step__image">
                            <img src={slider_result_2} alt=""/>
                        </div>
                    </div>
                    <div id="how_it_works__slider__result__step_3"
                         className={`how_it_works__slider__result__step ${activeIndex === 3 ? 'active' : ''}`}>
                        <div className="how_it_works__slider__result__step__text">
                            <div className="how_it_works__slider__result__step__text__title">
                                <div className="how_it_works__slider__result__step__text__title__image">
                                    <img src={slider_pick_3} alt=""/>
                                </div>
                                <div className="how_it_works__slider__result__step__text__title__text">Залишайте заявку
                                    на створення Telegram бота
                                </div>
                            </div>
                            <div className="how_it_works__slider__result__step__info">
                                <div className="how_it_works__slider__result__step__info__left">
                                    <img src={slider_result_3} alt=""/>
                                </div>
                                <div className="how_it_works__slider__result__step__info__right">
                                    <div className="how_it_works__slider__result__step__text__description">Заповнюєте
                                        форму в Telegram боті для створення заявки на підключення свого бізнесу до нашої
                                        системи
                                    </div>
                                    <div className="how_it_works__slider__result__step__text__button">
                                        <StartButton/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="how_it_works__slider__result__step__image">
                            <img src={slider_result_3} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorksSection;
