import React, {useEffect, useState} from 'react';
import phone_start from '../images/phone-start.png';
import phone_analytics from '../images/phone-analytics.png';
import "react-router-dom";
import "react-router-dom";
import {useLocation} from "react-router-dom";
import 'classnames';
import StartButton from "./ buttons/StartButton";
import MoreButton from "./ buttons/MoreButton";


// refresh
const Header = function () {
    const location = useLocation();
    const {pathname} = location;
    const splitLocation = pathname.split("/");
    const [isActiveBurger, setIsActiveBurger] = useState(false);

    useEffect(() => {
        const preventDefault = (e) => {
            e.preventDefault();
        };
        const preventScroll = () => {
            document.body.style.overflow = 'hidden';
        };
        const enableScroll = () => {
            document.body.style.overflow = 'auto';
        };
        if (isActiveBurger) {
            window.addEventListener('touchmove', preventDefault, {passive: false});
            preventScroll();
            document.body.classList.add('lock');
        } else {
            document.body.classList.remove('lock');
        }

        return () => {
            document.body.classList.remove('lock');
            window.removeEventListener('touchmove', preventDefault);
            enableScroll();
        };
    }, [isActiveBurger]);
    const toggleActive = () => {
        setIsActiveBurger(!isActiveBurger);
    };

    return (
        <header className={isActiveBurger ? 'activeBurger' : ''}>
            <div className="header__main">
                <div className="header__top">
                    <a href="/" className="header__logotype">
                        <div className="header__logotype__title">Nemus</div>
                        <div className="header__logotype__subtitle">Automate your business</div>
                    </a>
                    <div className="header__nav">
                        <div className="header__switcher">🇬🇧 Ua</div>
                        <div className={isActiveBurger ? 'header__burger active' : 'header__burger'}
                             onClick={toggleActive}><span></span></div>
                        <nav className={isActiveBurger ? 'header__navigation active' : 'header__navigation'}>
                            <ul className="header__navigation__list">
                                <li className={`header__navigation__list__item ${splitLocation[1] === "" ? "active" : ""}`}>
                                    <a href="/">Головна</a></li>
                                <li className={`header__navigation__list__item ${splitLocation[1] === "product" ? "active" : ""}`}>
                                    <a href="/product">Продукт</a></li>
                                <li className={`header__navigation__list__item ${splitLocation[1] === "prices" ? "active" : ""}`}>
                                    <a href="/prices">Тарифи</a></li>
                                <li className={`header__navigation__list__item ${splitLocation[1] === "contacts" ? "active" : ""}`}>
                                    <a href="/contacts">Контакти</a></li>
                            </ul>
                        </nav>
                        <StartButton/>
                    </div>
                </div>
                {
                    splitLocation[1] === "" && <div className="header__body">
                        <div className="header__body__text">
                            <div className="header__body__text__background"></div>
                            <div className="header__body__text__title">Telegram-боти для онлайн-записів.</div>
                            <div className="header__body__text__description">Nemus - ми команда, яка автоматизує Ваш
                                бізнес
                            </div>
                            <div className="header__buttons">
                                <StartButton/>
                                <MoreButton/>
                            </div>
                        </div>
                        <div className="header__body__phones">
                            <div className="header__body__phones__background"></div>
                            <div className="header__body__phones__first">
                                <img src={phone_start} alt="phone-start"/>
                            </div>
                            <div className="header__body__phones__second">
                                <img src={phone_analytics} alt="phone-analytics"/>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </header>
    );
};

export default Header;