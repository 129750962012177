import React, {useState} from 'react';

const ContactSection = function () {
	return (
		<div className="contact__section">
			<div className="contact__body">
				<div className="contact__body__title">
					Зберігайте зв'язок з нами!<br/>
					Залиште свій E-mail і ми Вам напишемо
				</div>
				<div className="contact__body__description">
					Замовлення розробки унікального Telegram-бота - це шанс відокремитись серед конкурентів та
					забезпечити свій бізнес незабутнім інтерактивним досвідом для користувачів. Ви можете замовити
					розробку бота з будь-якими функціями та можливостями, що відповідають вашим потребам. Таким чином,
					ви зможете створити унікальний інструмент для комунікації зі своїми клієнтами, що дозволить
					збільшити продажі та розширити аудиторію вашого бізнесу. Зверніться до нас і отримайте свій власний
					унікальний Telegram-бот вже сьогодні!
				</div>
				<div className="contact__body__form">
					<form action="">
						<div>
							<input className="contact__body__form__email" type="email" placeholder="Ваш E-mail"/>
						</div>
						<div>
							<input className="contact__body__form__submit" type="submit" value="Надіслати"/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ContactSection;