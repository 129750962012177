import React, {useState} from 'react';
import phone from '../images/phone.png';
import mail from '../images/mail.png';
import social_media from '../images/social_media.png';
import facebook from '../images/facebook.png';
import tiktok from '../images/tiktok.png';
import instagram from '../images/instagram.png';

const Footer = function () {
	return (
		<footer className="footer">
			<div className="footer__main">
				<div className="footer__main__body">
					<div className="footer__main__body__info">
						<div className="footer__main__body__info__logotype">
							<div className="header__logotype__title">Nemus</div>
							<div className="header__logotype__subtitle">Automate your business</div>
						</div>
						<div className="footer__main__body__info__description">
							Телеграм-боти для онлайн-запису, розроблені та налаштовані під потреби вашого бізнесу.
						</div>
						<div className="footer__main__body__info__email">
							<div className="footer__main__body__info__email__logo">
								<img src={mail} alt="mail"/>
							</div>
							<div className="footer__main__body__info__email__text">
								<span>nemusbots@gmail.com</span>
								<span>info@nemus.dev</span>
							</div>
						</div>
						<div className="footer__main__body__info__phone">
							<div className="footer__main__body__info__phone__logo">
								<img src={phone} alt="phone"/>
							</div>
							<div className="footer__main__body__info__phone__text">
								+380 (63) 799-88-08
							</div>
						</div>
					</div>
					<div className="footer__main__body__add">
						<div className="footer__main__body__add__title">
							Сторінки
						</div>
						<div className="footer__main__body__add__nav">
							<div>
								<a href="/">
									Головна
								</a>
							</div>
							<div>
								<a href="/product">
									Продукт
								</a>
							</div>
							<div>
								<a href="/prices">
									Тарифи
								</a>
							</div>
							<div>
								<a href="/contacs">
									Контакти
								</a>
							</div>
						</div>
					</div>
					<div className="footer__main__body__add">
						<div className="footer__main__body__add__title">
							Додаткові сторінки
						</div>
						<div className="footer__main__body__add__nav">
							<div>
								<a href="/privacy-policy">
									Політика конфіденційності
								</a>
							</div>
							<div>
								<a href="/user-agreement">
									Угода користувача
								</a>
							</div>
							{/*<div>FAQ</div>*/}
							{/*<div>Як це працює</div>*/}
						</div>
					</div>
					{/*<div className="footer__main__body__add">*/}
					{/*	<div className="footer__main__body__add__title social_media">*/}
					{/*		<img src={social_media} alt="social_media"/>*/}
					{/*	</div>*/}
					{/*	<div className="footer__main__body__add__nav logotypes">*/}
					{/*		<div>*/}
					{/*			<a href="https://www.instagram.com/fixum.tech/">*/}
					{/*				<img src={instagram} alt="instagram"/>*/}
					{/*			</a>*/}
					{/*		</div>*/}
					{/*		<div>*/}
					{/*			<a href="https://www.tiktok.com/uk-UA">*/}
					{/*				<img src={tiktok} alt="tiktok"/>*/}
					{/*			</a>*/}
					{/*		</div>*/}
					{/*		<div>*/}
					{/*			<a href="https://www.facebook.com/profile.php?id=100090612742506&locale=uk_UA">*/}
					{/*				<img src={facebook} alt="facebook"/>*/}
					{/*			</a>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>
				<div className="footer__main__copyright">
					© 2023 Nemus - Всі права захищені
				</div>
			</div>
		</footer>
	);
};

export default Footer;