import React from 'react';

const MoreButton = function () {
	return (
		<a href="/prices">
			<div className="product__buttons__more">Детальніше</div>
		</a>
	);
};

export default MoreButton;