import React from 'react';

const StartButton = function () {
	return (
		<a href="https://t.me/NemusBot">
			<div className="button__start">Розпочати</div>
		</a>
	);
};

export default StartButton;